import { useState } from 'react';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

import { resultData, resultData2 } from "../../types/upcheck/resultData";

import "./UpcheckPage.css";


export const UpcheckPage = () => {
    const [checking, setChecking] = useState(false);
    const [displayUrl, setDisplayUrl] = useState("");
    const [hasData, setHasData] = useState(false);
    const [hasResults, setHasResults] = useState(false);
    const [results, setResults] = useState<resultData2>([]);
    const [url, setUrl] = useState("");

    const check = async () => {
        setHasData(false);
        setHasResults(false);
        setChecking(true);
        setResults([]);

        const requestUrl = `https://tdvrtrlat7.execute-api.eu-west-2.amazonaws.com/v0/upcheck?url=${url}`;

        const options = {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            }
        };
        const response = await fetch(requestUrl, options);
        const responseOK = response && response.ok;
        if (responseOK) {
            const data = await response.json();
            setHasData(true);
            setHasResults(false);
            getResults(data.check_id);
        } else {
            // Need to write this out to an error or something as well here.

            setUrl("");
            setChecking(false);
            setHasData(false);
            setHasResults(false);
        }
    }

    const getResults = async(requestId:string) => {
        setTimeout(() => {
            getResult(requestId);
        }, 10000);
    }

    const getResult = async(requestId:string) => {
        const requestUrl = `https://tdvrtrlat7.execute-api.eu-west-2.amazonaws.com/v0/upcheck/${requestId}`;

        const options = {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        };
        const response = await fetch(requestUrl, options);
        const responseOK = response && response.ok;
        if (responseOK) {
            const data = await response.json();
            console.log(data);

            const x = [];
            x.push(data.results["eu-west-2"]);
            setResults(x);

            setDisplayUrl(url);
            setHasResults(true);
            setUrl("");
            setChecking(false);
        } else {
            getResults(requestId);
        }
    }

    return (
        <div className="requestInfo">
            <div id='pageTitle'>
                <h1>Upcheck!</h1>
            </div>
            <div id='upcheckForm'>
                <InputText id="urlInput" placeholder='https://example.com' value={url} onChange={(e) => setUrl(e.target.value)} />
                <Button id="upcheckButton" label="Check" loading={checking} onClick={check} />
            </div>
            { hasData ?
                <div className="results" id="results">
                    {
                        hasResults ?
                        <p id="results-url">Results for {displayUrl}</p>
                        :
                        <p id="results-url">Checking if {url} is up...</p>
                    }
                    <DataTable value={results} tableStyle={{ minWidth: '50rem' }}>
                        <Column field="region" header="Region"></Column>
                        <Column field="elapsed" header="Elapsed (ms)"></Column>
                        <Column field="status" header="Status"></Column>
                    </DataTable>
                </div>
                :
                <div></div>
            }
        </div>
    );
};