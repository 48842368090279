import { Route, Routes, useNavigate } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { Footer } from "./components/Footer";
import Header from "./components/Header";
import HomePage from "./pages/HomePage";
import LandingPage from "./pages/LandingPage";
import { SecuredRoute } from "./components/SecuredRoute";
import UpcheckPage from "./pages/UpcheckPage";

import "./App.css";

function App() {
    const navigate = useNavigate();

    return (
        <div className="flex flex-column min-h-screen">
            <div className="page-container flex flex-column flex-grow-1">
                <Header />

                <section className="content flex-grow-1">
                    <Routes>
                        <Route path="/" element={ <LandingPage /> } />
                        <Route path="/home" element={ <SecuredRoute><HomePage /></SecuredRoute> } />
                        <Route path="/upcheck" element={ <UpcheckPage /> } />

                        <Route path='*' element={ <LandingPage /> } />
                    </Routes>
                </section>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={true}
                    newestOnTop={true}
                    closeOnClick
                    pauseOnHover
                    theme="colored"
                    transition={Slide}
                />

            </div>
            <Footer />
        </div>
    );
}

export default App;
