import React, { useState, useEffect } from 'react';

import { useAuthenticator } from "@aws-amplify/ui-react";

import "./LandingPage.css";

export const LandingPage = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);

    useEffect(() => {
        if (authStatus === "authenticated") {
            window.location.href = "/home";
        }
    }, [authStatus]);

    return (
        <div>
            <h1>Monitor your systems and endpoint so you can sleep more easily at night!</h1>
            <div className="fp-content columns-3">
                <div>Do you know if your systems are working or not? Do you want to know when things change?</div>
                <div className="centered">
                    <i className="fa-solid fa-arrow-right fa-xl"></i>
                </div>
                <div>
                    <p>Monitron can help you to answer all of these questions and more!</p>
                    <p>Get alerts when your systems are not running.</p>
                </div>
            </div>
            <div className="fp-content centered">
                <div>
                    <button className="get-started-button">Get Started</button>
                </div>
                <div>
                    Get started on the free tier, no credit card required.
                </div>
            </div>
            <div className="fp-content columns-2">
                <div>Sleep peacefully</div>
                <div>Screenshot goes here</div>
            </div>
        </div>
    );
};